<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-900">
  
    <!-- Links Container -->
    <div class="bg-gray-800 bg-opacity-90 rounded-lg shadow-2xl p-8 max-w-3xl w-full animate-fadeInUp">
      
      <!-- Links Page Header -->
      <div class="text-center mb-8">
        <h2 class="text-3xl font-extrabold text-white mb-2">Your Created Links</h2>
        <p class="text-indigo-300">Here are the links you have created:</p>
      </div>
  
      <!-- Links List -->
      <div v-if="links.length > 0">
        <ul class="space-y-4">
          <li v-for="(link, index) in links" :key="index" class="flex justify-between items-center p-4 bg-gray-700 rounded-lg shadow-md">
            <span class="text-white">{{ "https://shortner.mattias-tonna.com/s/" + link.randomKey }}</span>
            <a :href="link.originalUrl" target="_blank" class="text-indigo-300 hover:underline">Visit</a>
            <button @click="deleteLink(link.randomKey)" class="text-red-500 hover:text-red-700">
              Delete
            </button>
          </li>
        </ul>
      </div>
      <div v-else>
        <p class="text-white text-center">You haven't created any links yet.</p>
      </div>
  
      <!-- Redirect Link to Create a Link -->
      <div class="mt-8 text-center">
        <router-link to="/" class="text-indigo-300 hover:underline">Create a new link</router-link>
      </div>
    </div>
  
  </div>
</template>

  <script>
  export default {
    data() {
      return {
        username: sessionStorage.getItem('username') || '',
        links: []
      };
    },
    methods: {
      // Simulating an API call to fetch links for the logged-in user
      async deleteLink(randomKey) {
      try {
        
        var check = await this.$store.dispatch('delete_link', { key: randomKey });
        console.log("link deleted successfully:", check);
        this.fetchLinks();

      } catch (error) {
        console.error("Error deleting link:", error);
        alert("There was an error deleting the link.");
      }
    }, 

      async fetchLinks() {
        if (!this.username) {
          // Redirect to login if no username is found in session
         this.$router.push('/login');
          return;
        }
        try {
          var links = await this.$store.dispatch('fetchUrls', { username: this.username});
          if(links === null) {
          this.links = []; 
          }
          else{
          this.links = links;
          }
        } catch (error) {
          console.error("Error fetching links:", error);
        }
      }
    },
    mounted() {
      this.fetchLinks(); // Fetch user links when the page loads
    }
  };
  </script>
  
  <style scoped>
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fadeInUp {
    animation: fadeInUp 0.5s ease-out;
  }
  
  ul {
    padding-left: 0;
  }
  
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #2d3748;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  li a {
    color: #63b3ed;
    text-decoration: none;
  }
  
  li a:hover {
    text-decoration: underline;
  }
  </style>
  