<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <div class="w-full max-w-2xl bg-gray-800 bg-opacity-80 rounded-lg shadow-2xl p-8 animate-fadeIn">
      <div class="text-center mb-12">
        <h1 class="text-5xl font-extrabold text-white mb-4 animate-bounce">
          🚀 Welcome to <span class="text-purple-500">Shortly</span>
        </h1>
        <p class="text-gray-300 text-lg max-w-md mx-auto">
          Simplify your links with ease and professionalism. Powered by modern technology.
        </p>
      </div>

      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div>
          <label for="urlInput" class="block text-sm font-medium text-gray-400 mb-2">Enter Your URL</label>
          <input id="urlInput" type="text" v-model="get_url" placeholder="e.g., https://www.example.com"
            class="w-full px-4 py-3 bg-gray-900 text-white rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 placeholder-gray-500 transition duration-300 ease-in-out" />
        </div>

        <button type="submit"
          class="w-full bg-gradient-to-r from-purple-600 to-purple-800 hover:from-purple-700 hover:to-purple-900 text-white font-bold py-3 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition-transform transform hover:scale-105">
          Generate Short URL
        </button>
      </form>


      <div v-if="result" class="mt-8 text-center animate-fadeInDown">
        <p class="text-lg text-gray-300 mb-2">Your shortened URL:</p>
        <a id="shortUrl" :href="shortened_url" target="_blank" rel="noopener noreferrer"
          class="text-lg font-medium text-blue-400 underline hover:text-blue-500 transition-all duration-300">
          {{ shortened_url }}
        </a>
      </div>


      <div v-if="error" class="mt-8 text-center animate-fadeInUp">
        <p class="text-lg text-red-500 font-semibold">Error:</p>
        <p class="text-sm text-gray-300">{{ disp_error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';


export default {
  name: 'Body',
  data() {
    return {
      resultVisible: false,
      shortUrl: '',
      get_url: "",
      result: false,
      shortened_url: "",
      error: false,
      disp_error: ""

    };
  },
  methods: {
    async handleSubmit() {
    const user = sessionStorage.getItem('username'); // Assuming you're using sessionStorage to store the username

    if (!user) {
        this.error = true;
        this.disp_error = "Please login to shorten a link";
    } else {
        this.error = false;

        // Await the result from getLink
        this.shortened_url = await this.$store.dispatch('getLink', { url: this.get_url, username: user });
        console.log("Test: " + this.shortened_url);

        if (this.shortened_url === "count") {
            // Handle rate limit exceeded
            this.result = false;
            this.error = true;
            this.disp_error = "Count exceeded for user: " + user;
        } else if (this.shortened_url === "") {
            // Handle invalid URL case
            this.result = false;
            this.error = true;
            this.disp_error = "Please enter a proper URL";
        } else {
            // Successful case
            this.error = false;
            this.result = true;
        }
    }
},


  },
};
</script>

<style scoped>
/* Add some subtle animations */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/* Add subtle animations for buttons and links */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.8s ease-out;
}

.animate-fadeInDown {
  animation: fadeInDown 0.8s ease-out;
}

.animate-fadeInUp {
  animation: fadeInUp 0.8s ease-out;
}
</style>