import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AccountView from '../views/AccountView.vue'
import LoginView from '@/views/LoginView.vue'
import registerView from '@/views/RegisterView.vue'
import LinkView from '@/views/LinkView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, 
  {
    path: '/profile',
    name: 'profile',
    component: AccountView
  }, 
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }, 
  {
    path: '/register',
    name: 'register',
    component: registerView
  },
  {
    path: '/links',
    name: 'links',
    component: LinkView
  },

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
