<template>
    <div class="flex flex-col items-center justify-center min-h-screen py-16">
        <!-- Profile Form -->
        <div class="w-full max-w-2xl bg-gray-800 bg-opacity-80 rounded-lg shadow-2xl p-8 animate-fadeInUp">
            <div class="text-center mb-8">
                <h2 class="text-3xl font-extrabold text-white my-3">My Profile</h2>
                <p class="text-indigo-300">Update your personal information below</p>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="field">
                    <label for="username" class="block text-sm font-semibold text-white">Username</label>
                    <input type="text" id="username" v-model="username" class="input-field"
                        placeholder="Enter your username" disabled />
                </div>

                <div class="field">
                    <label for="email" class="block text-sm font-semibold text-white">Email</label>
                    <input type="email" id="email" v-model="email" class="input-field" placeholder="Enter your email" />
                </div>

                <div class="field">
                    <label for="mobile" class="block text-sm font-semibold text-white">Mobile Number</label>
                    <input type="tel" id="mobile" v-model="mobile" class="input-field"
                        placeholder="Enter your mobile number" />
                </div>

                <div class="field">
                    <label for="password" class="block text-sm font-semibold text-white">Password</label>
                    <input type="password" id="password" v-model="password" class="input-field"
                        placeholder="Enter your password" />
                </div>

                <div class="col-span-1 md:col-span-2">
                    <button type="submit" @click="updateProfile"
                        class="w-full py-3 mt-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 transition-all duration-300 transform hover:scale-105">
                        Save Changes
                    </button>
                </div>

                <!-- Delete Account Button -->
                <div class="col-span-1 md:col-span-2 mt-4">
                    <button @click="deleteAccount"
                        class="w-full py-3 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition-all duration-300 transform hover:scale-105">
                        Delete Account
                    </button>
                </div>

                <!-- Sign Out Button -->
                <div class="col-span-1 md:col-span-2 mt-4">
                    <button @click="signOut"
                        class="w-full py-3 bg-gray-600 text-white font-semibold rounded-md hover:bg-gray-700 transition-all duration-300 transform hover:scale-105">
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            username: '',
            email: '',
            mobile: '',
            password: '',
            hidden_pass: '',
        };
    },
    methods: { 
        async updateProfile(){
            const data = await this.$store.dispatch('update_user', {
                username: this.username,
                email: this.email,
                mobile: this.mobile,
            });
            console.log(data);
        }, 
        async deleteAccount() {
            if (confirm("Are you sure you want to delete your account? This action is irreversible.")) {
                try {
                    const data = await this.$store.dispatch('delete_user', { username: this.username });
                    console.log("Account deleted successfully:", data);
                    sessionStorage.removeItem('username');
                    this.$router.push('/login');

                } catch (error) {
                    console.error("Error deleting account:", error);
                    alert("Error deleting account. Please try again.");
                }
            }
        }, 
        signOut() {
            
            sessionStorage.removeItem('username');
            
            this.$router.push('/login');
        }
    }, 

    async mounted() {
        
        const user = sessionStorage.getItem('username'); 
        if (!user) {
            this.$router.push('/login'); 
        } else {

            var data = await this.$store.dispatch('fetch_user', { username: user });
            console.log(data);
            if (data) {
                this.email = data.email;
                this.mobile = data.mobile;;
            }
            this.username = user; 
        }
    }
};
</script>

<style scoped>
.profile-page {
    padding-top: 8rem;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fadeInUp {
    animation: fadeInUp 0.5s ease-out;
}

@keyframes inputFocus {
    from {
        transform: scale(1);
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    }
    to {
        transform: scale(1.05);
        box-shadow: 0 0 8px rgba(0, 140, 255, 0.8);
    }
}

.input-field {
    width: 100%;
    padding: 12px 16px;
    background-color: #1f2937;
    color: white;
    border-radius: 8px;
    border: 2px solid #374151;
    transition: all 0.3s ease-in-out;
    margin-top: 8px;
}

.input-field:focus {
    outline: none;
    animation: inputFocus 0.2s ease-out forwards;
    border-color: #6366f1;
}

button {
    transition: all 0.3s ease-in-out;
}

button:hover {
    transform: scale(1.05);
    background-color: #4338ca;
}

::placeholder {
    color: #6b7280;
    opacity: 1;
}
</style>
