<template>
  
<loginvue/> 
</template>

<script>
import loginvue from '@/components/login.vue';
export default {
  components: { loginvue },

}
</script>

<style>

</style>