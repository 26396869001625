<template>
  <links/>
</template>

<script>
import links from '@/components/links.vue';
export default {
    components: { links },

}
</script>

<style>

</style>