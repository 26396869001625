import { createStore } from 'vuex'
import axios from 'axios'


export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getLink({ state }, { url, username }) {
      try {
        console.log('Request URL:', `https://shortner.mattias-tonna.com/s/shorten?url=${url}&username=${username}`);

        // Destructure the `data` directly from the response
        const { data } = await axios.post(
          `https://shortner.mattias-tonna.com/s/shorten?username=${username}&url=${url}`,
          {}, // Empty body
          {
            headers: {
              'app_id': process.env.VUE_APP_KEY,
            },
          }
        );

        console.log('Response Data:', data); // `data` will directly hold the response data

        // Check if the data object contains the 'link' field
        if (data && data.link) {
          return "https://shortner.mattias-tonna.com/s/" + data.link; // Return the full shortened link
        } else {
          console.error("No link found in the response data");
          return ""; // If no link is present
        }

      } catch (error) {
        if (error.response) {
          console.log('Error Status:', error.response.status);

          // Check for specific status codes
          if (error.response.status === 429) {
            return "count"; // Too Many Requests
          } else {
            return ""; // Default fallback for other errors
          }
        }

        // Handle other kinds of errors (network issues, etc.)
        console.error('Unexpected Error:', error);
        return "";
      }
    },


    async createUser({ state }, { username, mobile, email, password }) {
      try {

        const Body = {
          username: username,
          mobile: mobile,
          email: email,
          password: password
        }


        const { data } = await axios.post(`https://shortner.mattias-tonna.com/s/users/create`, Body, {
          headers: {
            'app_id': process.env.VUE_APP_KEY
          }
        })
        return true

      } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
      }
    },

    async login({ state }, { username, password }) {
      try {
        const Body = {
          username: username,
          password: password
        }
        const { data } = await axios.post(`https://shortner.mattias-tonna.com/s/users/authenticate`, Body, {
          headers: {
            'app_id': process.env.VUE_APP_KEY
          }
        })
        console.log(data)
        return data.status

      } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
        return false
      }
    },
    async fetchUrls({ state }, { username }) {
      try {
        const { data } = await axios.get(`https://shortner.mattias-tonna.com/s/users/links?username=${username}`, {
          headers: {
            'app_id': process.env.VUE_APP_KEY
          }
        })
        return data
      } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
        return

      }
    }, 
    async fetch_user({ state }, { username }) { 
      try {
        const { data } = await axios.get(`https://shortner.mattias-tonna.com/s/users/${username}`, {
          headers: {
            'app_id': process.env.VUE_APP_KEY
          }
        })
        return data
      } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
        return 

      }
    },
    async update_user({ state }, { username, email, mobile, password }) {
      try {
          const body = {
              username: username,
              email: email,
              mobile: mobile,
              password: password // Ignore this if you're not updating it anymore
          };

          console.log('Sending data:', body);

          const { data } = await axios.put(`https://shortner.mattias-tonna.com/s/users/update`, body, {
              headers: {
                  'app_id': process.env.VUE_APP_KEY // Ensure this key is correctly set
              }
          });
          console.log('Received response:', data);

          return data;
      } catch (Error) {
          console.error('Error occurred:', Error); // Log the error for debugging
          return;
      }
  }, 
  async delete_user({ state }, { username }) {
    try {
        const { data } = await axios.delete(`https://shortner.mattias-tonna.com/s/users/delete?username=${username}`, {
            headers: {
                'app_id': process.env.VUE_APP_KEY // Ensure this key is correctly set
            }
        });
        console.log('Received response:', data);

        return data;
    } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
        return;
    }
  },

  async delete_link({ state }, {key }) {
    try {
        const { data } = await axios.delete(`https://shortner.mattias-tonna.com/s/links/delete?randomKey=${key}`, {
            headers: {
                'app_id': process.env.VUE_APP_KEY // Ensure this key is correctly set
            }
        });
        console.log('Received response:', data);

        return data;
    } catch (Error) {
        console.error('Error occurred:', Error); // Log the error for debugging
        return;

  }
}, 
  },
  modules: {
  }
})
