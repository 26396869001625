<template>
    <profile/> 
  </template>
  
  <script>
  import profile from '@/components/profile.vue';
  export default {
    components:{ 
        profile, 
    }
  
  }
  </script>
  
  <style>
  
  </style>