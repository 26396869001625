<template>
    <header class="bg-gradient-to-r from-purple-800 via-indigo-800 to-black shadow-lg fixed top-0 left-0 w-full z-50">
        <nav class="container mx-auto flex items-center justify-between">
            <div class="text-2xl font-extrabold text-white">
                <a href="/" class="hover:text-indigo-300 transition duration-300">Shortly</a>
            </div>

            <button @click="toggleMenu"
                class="w-24 h-24 flex items-center justify-center transform transition-transform duration-300"
                :class="{ 'rotate-180': isMenuOpen }">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="white"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polygon points="12,2 2,22 22,22" />
                </svg>
            </button>
        </nav>

        <div v-if="isMenuOpen" class=" text-white py-4 animate-fadeInDown absolute w-full top-full left-0 z-40">
            <div class="container mx-auto text-center">
                <ul class="flex justify-between">
                    <div class="flex justify-start gap-12 media_queries">
                        <li>
                            <a href="/" class="font-bold text-white text-lg hover:text-indigo-400 transition duration-300">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/profile" class="font-bold text-white text-lg hover:text-indigo-400 transition duration-300">
                                Account
                            </a>
                        </li>
                        <li>
                            <a href="/links" class="font-bold text-white text-lg hover:text-indigo-400 transition duration-300">
                                links
                            </a>
                        </li>
                    </div>

                    <li>
                        <p class="font-bold text-white/50 text-lg " v-if="username" >
                            signed in as: {{username}}
                        </p>
                        <p class="font-bold text-white/50 text-lg " v-if="!username">
                            not signed it
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            username: null,
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        check() {
            const user = sessionStorage.getItem('username');
            this.username = user || null;
        },
        storageEventListener(event) {
            if (event.key === 'username') {
                this.username = event.newValue || null; 
            }
        }
    },
    mounted() {
        this.check(); 
        this.intervalId = setInterval(() => {
            this.check();
        }, 60000); 
    },
    beforeDestroy() {
        // Clear the interval to avoid memory leaks
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
};
</script>

<style scoped>

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 600px) {


    .lg\:hidden {
        display: block !important;
    }

    .lg\:flex {
        display: none !important;
    }

    .space-y-4 {
        margin-top: 1rem;
    }


    .text-center ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .media_queries{ 
        flex-direction: column;
        gap: 1rem; 
    }
}

.animate-fadeInDown {
    animation: fadeInDown 0.3s ease-in-out;
}
</style>