<template>
  
    <register/>
</template>

<script>
import register from '@/components/register.vue';
export default {
components: { register },

}
</script>

<style>

</style>