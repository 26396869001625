<template>
  <BodyVue/> 
</template>

<script>
import BodyVue from '../components/Body.vue'
export default {
  components:{ 
    BodyVue, 
  }

}
</script>

<style>

</style>