<template>
    <div class="flex flex-col items-center justify-center min-h-screen py-16">
        <!-- Profile Form -->
        <div class="w-full max-w-2xl bg-gray-800 bg-opacity-80 rounded-lg shadow-2xl p-8 animate-fadeInUp">

            <div class="text-center mb-8">
                <h2 class="text-3xl font-extrabold text-white mb-2">Create an Account</h2>
                <p class="text-indigo-300">Please fill in the details to register</p>
            </div>

            <!-- Register Form -->
            <form @submit.prevent="handleRegister">
                <!-- Username Field -->
                <div class="mb-4">
                    <label for="username" class="block text-sm font-semibold text-white">Username</label>
                    <input type="text" id="username" v-model="username" class="input-field"
                        placeholder="Enter your username" required />
                </div>

                 <!-- Email Field -->
                 <div class="mb-4">
                    <label for="mobile" class="block text-sm font-semibold text-white">Mobile</label>
                    <input type="text" id="mobile" v-model="mobile" class="input-field" placeholder="Enter your mobile"
                        required />
                </div>

                <!-- Email Field -->
                <div class="mb-4">
                    <label for="email" class="block text-sm font-semibold text-white">Email</label>
                    <input type="email" id="email" v-model="email" class="input-field" placeholder="Enter your email"
                        required />
                </div>

                <!-- Password Field -->
                <div class="mb-6">
                    <label for="password" class="block text-sm font-semibold text-white">Password</label>
                    <input type="password" id="password" v-model="password" class="input-field"
                        placeholder="Enter your password" required />
                </div>

                <!-- Submit Button -->
                <button type="submit"
                    class="w-full py-3 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 transition-all duration-300 transform hover:scale-105">
                    Register
                </button>

                <!-- Login Redirect Link -->
                <p class="mt-4 text-center text-white">
                    Already have an account?
                    <a href="/login" class="text-indigo-300 hover:underline">Login</a>
                </p>
            </form>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            email: '',
            mobile: '',
            password: ''
        };
    },
    methods: {
        async handleRegister() {
            
            if (this.username && this.mobile && this.email && this.password) {

                if (this.mobile.length != 8) {
                    alert("Mobile number should be 8 digits");
                    return;
                }
                
                var check = await this.$store.dispatch('createUser', { username: this.username, mobile: this.mobile, email: this.email, password: this.password });
                if (check == false) {
                    alert("User already exists");
                    return;
                }
                sessionStorage.setItem('username', this.username);
                this.$router.push('/profile');
            } else {
                alert('Please fill in all fields.');
            }
        }
    }
};
</script>

<style scoped>
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fadeInUp {
    animation: fadeInUp 0.5s ease-out;
}

.input-field {
    width: 100%;
    padding: 12px 16px;
    background-color: #1f2937;
    color: white;
    border-radius: 8px;
    border: 2px solid #374151;
    transition: all 0.3s ease-in-out;
    margin-top: 8px;
}

.input-field:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 5px rgba(99, 102, 241, 0.7);
}
</style>